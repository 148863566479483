import React, { useState, useEffect } from 'react';

const NomineeDetails = ({ nominee, onClose, onDelete }) => {
    const [nomineeDetails, setNomineeDetails] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        const fetchNomineeDetails = async () => {
            try {
                const response = await fetch(`${apiUrl}/api/users/${nominee.user}/nominees/${nominee.id}/`);
                if (!response.ok) {
                    throw new Error('Failed to fetch nominee details');
                }
                const data = await response.json();
                setNomineeDetails(data);
            } catch (error) {
                console.error('Error fetching nominee details:', error);
            }
        };

        if (nominee && nominee.id) {
            fetchNomineeDetails();
        }
    }, [nominee, apiUrl]);

    const handleDelete = async () => {
        try {
            // Fetch notes associated with the nominee
            const response = await fetch(`${apiUrl}/api/users/${nominee.user}/notes/nominees/${nominee.id}/`);
            if (!response.ok) {
                throw new Error('Failed to fetch notes');
            }
            const notes = await response.json();

            // Check if there are any notes associated with the nominee
            if (notes.length > 0) {
                setErrorMessage('Cannot delete a nominee assigned to a note.');
                return;
            }
            // If no notes are associated, proceed with the deletion
            onDelete(nominee.id);
        } catch (error) {
            console.error('Error deleting nominee:', error);
            setErrorMessage('An error occurred while deleting the nominee.');
        }
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
            <div className="bg-white rounded-lg w-full md:max-w-xl overflow-hidden">
                <div className="flex justify-between items-center px-4 py-2 bg-color-navbar">
                    <h2 className="text-lg font-semibold text-black">Nominee Details</h2>
                    <button onClick={onClose} className="text-black focus:outline-none hover:bg-custom-orange rounded-full p-2">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>
                <div className="p-6 mt-2 mb-4">
                    <h2 className="text-2xl font-semibold  mb-4 text-gray-800">{nominee.name}</h2>
                    <p className="text-sm">Email: {nominee.email_id}</p>
                    {nomineeDetails && (
                        <div>
                            <p>{/* Display additional details here */}</p>
                        </div>
                    )}
                    {errorMessage && (
                        <p className="text-red-500 mt-4">{errorMessage}</p>
                    )}
                    <button onClick={handleDelete} className="bg-custom-orange text-white rounded-md px-4 py-2 mt-6 focus:outline-none hover:bg-orange-600 transition-colors duration-100">Delete</button>
                </div>
            </div>
        </div>
    );
};

export default NomineeDetails;