import React from 'react';
import aboutusimg from '../assets/Firefly generate a indian theme simple vector with theme light blue and orange based on security,ete 2.png';
import '@fontsource/montserrat'; 

const AboutUs = () => {
  return (
    <div className="flex flex-col md:flex-row p-6 font-montserrat">
      <div className="md:w-1/2 p-4">
        <img src={aboutusimg} alt="About Us" className="" />
      </div>
      <div className="md:w-1/2 p-2 mt-0 md:mt-20 md:pl-6">
        <h1 className="md:text-5xl font-bold md:mb-16 mb-8 text-3xl text-center md:text-left text-orange-500">WHO WE ARE?</h1>
        <div className="max-w-xl">
          <p className="md:mb-16 mb-4 md:text-xl text-sm ml-2 md:leading-loose leading-6">
            Secure your confidential details and information with us and automatically share 
            secure your confidential details and information with us and automatically share it to a nominee assigned by you after your death.
          </p>
          <p className="md:mb-16 mb-4 md:text-xl text-sm ml-1 md:leading-loose leading-6">
            Secure your confidential details and information with us and automatically share it to a nominee assigned by you after your death.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
