import React from 'react'
// import HomeNavbar from './homeNavbar'
import HomeLayout from './homeLayout'
const Home = () => {
  return (
    <div>     
        <HomeLayout></HomeLayout>
    </div>
  )
}
export default Home