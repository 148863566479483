import React,{ useContext} from 'react'
import Navbar from '../navbar/navbar';
import './landing.css';
import landingimg from '../assets/Firefly generate a image for landing page of startup which secure your details even after your death (3) 1.png'
import shield from '../assets/secure-shield 1.png'
import lock from '../assets/padlock 1.png'
import group from '../assets/Group 1.png'
import { Link } from 'react-router-dom';
import AboutUs from '../aboutUs/AboutUs';
import UserContext from '../ContextApi/UserContext';
import Footer from '../Footer/Footer';


const Landing = () => {
  const { user } = useContext(UserContext);
  return (
    // <div className='lan'>
    <>
     <Navbar></Navbar>
    <div className='landing-main'>
        <div className='landing'>
          <div className='container'> 
                  <div className='text'>
                      <div className='landing-images-1'>
                        {/* <img src={lock} alt='lock' className='img-lock'></img> */}
                        <img src={shield} alt='shield' className='img-shield'></img>
                        <img src={group} alt='group' className='img-group'></img>
                      </div>
                      <h1 className='text-secure'><span className='orange'>Secure your</span><br/><span className='text-details'>details with us</span> </h1>
                      <h1 className='text-p'>Secure your confidential details and information with us and automatically share it to a nominee assigned by you after your death.</h1>
                      {user ? (
        <Link to='/home'>
          <button className='landing-btn'>Get started</button>
        </Link>
      ) : (
        <Link to='/register'>
          <button className='landing-btn'>Get started</button>
        </Link>
      )}
                  
                  <div className='landing-images-2'>
                      {/* <img src={shield} alt='shield' className='img-shield2'></img> */}
                      <img src={lock} alt='lock' className='img-lock2'></img>
                  </div>
                  </div>
                  <div className='img-landing'>
                    <img className='image' src={landingimg} alt="image" />
                  </div>
              </div>
          </div>
        </div>
        <AboutUs></AboutUs>
        <Footer></Footer>
         </>
    // </div>
  )
}

export default Landing