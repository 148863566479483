import React, { useContext, useState, useRef, useEffect } from 'react';
import { FaUserCircle } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import UserContext from '../ContextApi/UserContext';
import '@fontsource/montserrat';
import '@fontsource/jockey-one';
import './homeNavbar.css';

const HomeNavbar = () => {
  const { user, logout } = useContext(UserContext);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const dialogRef = useRef(null);
  const navigate = useNavigate();

  const handleIconClick = () => {
    setIsDialogOpen(!isDialogOpen);
  };

  const handleLogout = async () => {
    try {
      console.log('Logout button clicked');
      await logout();
      window.location.href = '/loggedout';
    } catch (error) {
      console.error('Logout failed', error);
    }
  };

  const handleClickOutside = (event) => {
    if (dialogRef.current && !dialogRef.current.contains(event.target)) {
      setIsDialogOpen(false);
    }
  };

  useEffect(() => {
    if (isDialogOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isDialogOpen]);

  return (
    <nav className="p-4 flex justify-between items-center" style={{ backgroundColor: '#FFDDA4' }}>
      <div className="text-#004170 font-semibold text-xl ml-6 font-montserrat" >
      <Link to="/home" className="font-normal text-4xl tracking-narrow" style={{ color: '#FF7B00', fontFamily: 'Jockey One' }}>anantar</Link>
      </div>
      <div className="flex items-center space-x-12 font-normal font-montserrat text-xl mr-6 relative">
        <Link to="/help" className="text-black">Help</Link>
        <FaUserCircle className="text-2xl cursor-pointer text-orange-500" onClick={handleIconClick} />
        {isDialogOpen && (
          <div ref={dialogRef} className="absolute top-full right-0 mt-2 w-48 bg-white shadow-lg rounded-lg p-4 z-10">
            <button
              className="w-full h-full bg-custom-orange hover:bg-custom-orange-hover text-white font-semibold py-2 px-4 rounded flex items-center justify-center"
              onClick={handleLogout}
            >
              Logout
            </button>
          </div>
        )}
      </div>
    </nav>
  );
};

export default HomeNavbar;
