import React from 'react';
import { FaLocationDot } from "react-icons/fa6";
import SocialButton from './Button/socialIcons';

const Footer = () => (
    <div className="bg-custom-orange text-white p-5 flex flex-col ">
        {/* <img src={building} className='max-w-full object-cover' alt='building' /> */}
        <div className="flex justify-around flex-wrap mt-8">
            <div className="flex flex-col justify-center gap-2 font-montserrat">
                <h3 className="font-bold font-montserrat">QUICK LINK</h3>
                <a className="font-montserrat text-white text-opacity-70 font-normal text-lg tracking-widest">About</a>
                <a className="text-white text-opacity-70 font-normal text-lg tracking-widest">Contact us</a>
                <a className="text-white text-opacity-70 font-normal text-lg tracking-widest">Roadmap</a>
                <a className="text-white text-opacity-70 font-normal text-lg tracking-widest">FAQs</a>
            </div>
            <div className="flex flex-col items-center justify-around mt-4 md:mt-0">
                {/* <img src={logo} className='w-80' alt=" logo" /> */}
                <div className="flex justify-around">
                    <SocialButton />
                </div>
            </div>
            <div className="mt-16 flex flex-col items-center justify-around text-white text-opacity-70 font-normal text-lg leading-7">
                <FaLocationDot className="text-xl mb-2" />
                <p>The LNM Institute of
                    <br /> Information Technology
                    <br /> Jaipur, Rajasthan
                    <br />(302031)</p>
            </div>
        </div>
        <div className="text-center mt-5 text-white text-opacity-70 font-normal text-lg leading-7 border-t border-white border-opacity-50 pt-5">
            <p>© 2024 All Rights Reserved. Anantar®</p>
        </div>
    </div>
);

export default Footer;
