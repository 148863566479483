import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import  { useContext, useState } from 'react';
import UserContext from '../ContextApi/UserContext';

const TokenHandler = () => {
  const navigate = useNavigate();
  const { user, fetchUser } = useContext(UserContext) || { user: null, fetchUser: () => {} };
  useEffect(() => {
    const extractAccessToken = async () => {
      const hash = window.location.hash;
      const token = new URLSearchParams(hash.replace('#', '?')).get('access_token');
      if (token) {
        const requestOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ access_token: token }),
          credentials: 'include' 
        };
        try {
          const response = await fetch(`${process.env.REACT_APP_API_URL}/api/auth/google/callback/`, requestOptions);
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          else
          {
            navigate('/home');
            fetchUser();
          }
          
        } catch (error) {
          console.error('Error sending token to backend:', error);
        }
      } else {
        console.error('Access token not found');
        navigate('/register');
      }
    
    };
    extractAccessToken();
  }, [navigate]);

  return <div>Processing authentication...</div>;
};
export default TokenHandler;
