import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import UserContext from '../ContextApi/UserContext';

const RedirectRoute = ({ element, redirectTo }) => {
  const { user } = useContext(UserContext);
  return user ? <Navigate to={redirectTo} /> : element;
};

export default RedirectRoute;
