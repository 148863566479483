import React, { useState, useEffect, useContext } from "react";
import { FaStickyNote, FaPlus } from "react-icons/fa";
import NomineeForm from "./NomineeForm";
import NomineeDetails from "./NomineeDetails";
import UserContext from "../ContextApi/UserContext";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion"; 

const Nominees = () => {
  const [nominees, setNominees] = useState([]);
  const navigate = useNavigate();
  const [showForm, setShowForm] = useState(false);
  const [selectedNominee, setSelectedNominee] = useState(null);
  const [searchTerm, setSearchTerm] = useState(""); 
  const { user,getCookie } = useContext(UserContext);
  let userId = "";
  if (user) {
    userId = user._id;
  }

  const apiUrl = process.env.REACT_APP_API_URL;

  const fetchNominees = async () => {
    try {
      const response = await fetch(`${apiUrl}/api/users/${userId}/nominees/`);
      if (!response.ok) {
        throw new Error("Failed to fetch nominees");
      }
      const data = await response.json();
      setNominees(data);
    } catch (error) {
      console.error("Error fetching nominees:", error);
    }
  };

  useEffect(() => {
    if (userId) {
      fetchNominees();
    }
  }, [userId, apiUrl]);

  const handleAddNominee = (nominee) => {
    setNominees([...nominees, nominee]);
    setShowForm(false);
  };

  const handleShowForm = () => {
    setShowForm(true);
  };

  const handleCloseForm = () => {
    setShowForm(false);
  };

  const handleNomineeClick = (nominee) => {
    setSelectedNominee(nominee);
  };

  const handleCloseDetails = () => {
    setSelectedNominee(null);
  };

  const handleDeleteNominee = async (id) => {
    console.log("selectedNominee", selectedNominee);

    try {
      const csrftoken = getCookie("csrftoken"); // Fetch CSRF token

      const response = await fetch(
        `${apiUrl}/api/users/${userId}/nominees/${id}/delete/`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": csrftoken, // Include CSRF token in headers
          },
          credentials: "include", // Include credentials (cookies) in the request
        }
      );

      if (!response.ok) {
        throw new Error("Failed to delete nominee");
      }

      setNominees(nominees.filter((nominee) => nominee.id !== id));
      setSelectedNominee(null);
    } catch (error) {
      console.error("Error deleting nominee:", error);
    }
  };

  // Function to filter nominees based on search term
  const filteredNominees = nominees.filter((nominee) =>
    nominee.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="flex flex-col h-full relative">
      <div className="mb-4 ml-10 w-2/5">
        <input
          type="text"
          placeholder="Search..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="p-2 border border-gray-300 rounded-md w-full focus:outline-none focus:border-blue-500 transition-all duration-300"
        />
      </div>
      {searchTerm && filteredNominees.length === 0 && (
        <div className="flex justify-center items-center h-full">
          <p>No nominee of this name is present.</p>
        </div>
      )}
      {(searchTerm && filteredNominees.length === 0) ||
        (filteredNominees.length > 0 && (
          <div className="flex flex-col ml-10 gap-5 overflow-y-auto w-2/4">
            {filteredNominees.map((nominee) => (
              <div
                key={nominee.id}
                className="relative bg-white border border-gray-300 rounded-md p-4 flex items-center justify-between transition-all duration-300 hover:shadow-md cursor-pointer"
                onClick={() => handleNomineeClick(nominee)}
                style={{ borderLeft: "7px solid #FFE500" }}
              >
                <div>
                  <h3 className="font-semibold ml-10 mb-2 text-lg">
                    <span className="text-custom-orange">Name:</span>{" "}
                    {nominee.name}
                  </h3>
                  <p className="text-m ml-10 ">
                    <span className="text-custom-orange">email: </span>
                    {nominee.email_id}
                  </p>
                </div>
                <FaStickyNote className="text-black-500" />
              </div>
            ))}
          </div>
        ))}
      {!searchTerm && filteredNominees.length === 0 && (
        <div className="flex justify-center items-center h-full">
          <p>No nominees present, add nominee</p>
        </div>
      )}
      <motion.button
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
        className="bg-orange-500 text-white rounded-full w-12 h-12 flex items-center justify-center absolute bottom-4 right-4 focus:outline-none hover:bg-orange-600 transition-colors duration-300"
        onClick={handleShowForm}
      >
        <FaPlus />
      </motion.button>
      {showForm && (
        <NomineeForm onClose={handleCloseForm} onSubmit={handleAddNominee} />
      )}
      {selectedNominee && (
        <NomineeDetails
          nominee={selectedNominee}
          onClose={handleCloseDetails}
          onDelete={() => handleDeleteNominee(selectedNominee.id)}
        />
      )}
    </div>
  );
};

export default Nominees;
