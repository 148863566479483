import React, { useState, useEffect, useContext } from 'react';
import { motion } from 'framer-motion';
import { FaPlus } from 'react-icons/fa';
import NoteForm from './NoteForm';
import NoteDetails from './NoteDetails';
import UserContext from '../ContextApi/UserContext';

const Notes = ({ onNoteClick }) => {
    const [showForm, setShowForm] = useState(false);
    const [showDetails, setShowDetails] = useState(false);
    const [notes, setNotes] = useState([]);
    const [selectedNoteId, setSelectedNoteId] = useState(null);
    const [searchTerm, setSearchTerm] = useState("");
    const { user, getCookie } = useContext(UserContext);
    const apiUrl = process.env.REACT_APP_API_URL;
    let userId = "";

    if (user) {
        userId = user._id;
    }

    useEffect(() => {
        const fetchNotes = async () => {
            try {
                const response = await fetch(`${apiUrl}/api/users/${userId}/notes/`);
                if (!response.ok) {
                    throw new Error('Failed to fetch notes');
                }
                const data = await response.json();
                setNotes(data);
            } catch (error) {
                console.error('Error fetching notes:', error);
            }
        };

        if (userId) {
            fetchNotes();
        }
    }, [userId, apiUrl]);

    const handleAddNoteClick = () => {
        setShowForm(true);
    };

    const handleCloseForm = () => {
        setShowForm(false);
    };

    const handleSubmitNote = async (newNote) => {
        setNotes([...notes, newNote]);
        setShowForm(false);
    };

    const handleNoteClick = (noteId) => {
        setSelectedNoteId(noteId);
        setShowDetails(true);
    };

    const handleCloseDetails = () => {
        setShowDetails(false);
        setSelectedNoteId(null);
    };

    const handleDeleteNote = async (noteId) => {
        const csrftoken = getCookie('csrftoken'); // Ensure this function is correctly defined

        try {
            const response = await fetch(`${apiUrl}/api/users/${userId}/notes/${noteId}/delete/`, {
                method: 'DELETE',
                headers: {
                    'X-CSRFToken': csrftoken,
                },
            });

            if (!response.ok) {
                throw new Error('Failed to delete note');
            }

            setNotes(notes.filter(note => note.id !== noteId));
            setShowDetails(false);
            setSelectedNoteId(null);
        } catch (error) {
            console.error('Error deleting note:', error);
        }
    };

    const filteredNotes = notes.filter(note =>
        note.title.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className="flex flex-col h-full relative">
            <div className="mb-4 ml-10 w-2/5">
                <input
                    type="text"
                    placeholder="Search..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="p-2 border border-gray-300 rounded-md w-full focus:outline-none focus:border-blue-500 transition-all duration-300"
                />
            </div>
            {searchTerm && filteredNotes.length === 0 && (
                <div className="flex justify-center items-center h-full">
                    <p>No note with this title found.</p>
                </div>
            )}
            {(filteredNotes.length > 0 || !searchTerm) && (
                <div className="flex flex-col ml-10 gap-5 overflow-y-auto w-3/4">
                    {filteredNotes.map((note) => (
                        <div
                            key={note.id}
                            className="relative bg-white border border-gray-300 rounded-md p-4 flex items-center justify-between transition-all duration-300 hover:shadow-md cursor-pointer"
                            onClick={() => handleNoteClick(note.id)}
                            style={{ borderLeft: '7px solid #FFE500' }}
                        >
                            <div>
                                <h3 className="font-semibold ml-10 mb-2 text-lg"><span className='text-custom-orange'>Note Title: </span>{note.title}</h3>
                                <p className="text-m text-black"><span className='text-custom-orange ml-10'>Category: </span> {note.category}</p>
                            </div>
                            <button
                                className="absolute top-1 right-2 text-gray-500 p-2  rounded-full"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    // handleTriggerRequest(note.id);
                                }}
                            >
                                <div className='bg-custom-orange rounded-lg hover:bg-orange-800 ml-4 p-2 text-white'> Trigger</div>
                            </button>
                        </div>
                    ))}
                </div>
            )}
            {filteredNotes.length === 0 && !searchTerm && (
                <div className="flex justify-center items-center h-full">
                    <p>No notes are there, create one.</p>
                </div>
            )}
            <motion.button
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                className="bg-orange-500 text-white rounded-full w-12 h-12 flex items-center justify-center absolute bottom-4 right-4 focus:outline-none hover:bg-orange-600 transition-colors duration-300"
                onClick={handleAddNoteClick}
            >
                <FaPlus />
            </motion.button>
            {showForm && <NoteForm onClose={handleCloseForm} onSubmit={handleSubmitNote} />}
            {showDetails && <NoteDetails noteId={selectedNoteId} onClose={handleCloseDetails} onDelete={handleDeleteNote} />}
        </div>
    );
};

export default Notes;
