import React, { useState, useEffect, useContext } from 'react';
import UserContext from '../ContextApi/UserContext';

const NoteDetails = ({ noteId, onClose, onDelete }) => {
    const { user } = useContext(UserContext);
    const [noteDetails, setNoteDetails] = useState(null);
    const [nomineeDetails, setNomineeDetails] = useState(null);
    const [loading, setLoading] = useState(true);
    const userId = user._id;
    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        const fetchNoteDetails = async () => {
            try {
                const response = await fetch(`${apiUrl}/api/users/${userId}/notes/${noteId}/`);
                if (response.ok) {
                    const data = await response.json();
                    setNoteDetails(data);
                    if (data.nominee) {
                        fetchNomineeDetails(data.nominee);
                    } else {
                        setLoading(false); // If no nomineeId, stop loading
                    }
                } else {
                    throw new Error('Failed to fetch note details');
                }
            } catch (error) {
                console.error('Error fetching note details:', error);
            }
        };

        const fetchNomineeDetails = async (nomineeId) => {
            try {
                const response = await fetch(`${apiUrl}/api/users/${userId}/nominees/${nomineeId}/`);
                if (response.ok) {
                    const data = await response.json();
                    setNomineeDetails(data);
                } else {
                    throw new Error('Failed to fetch nominee details');
                }
            } catch (error) {
                console.error('Error fetching nominee details:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchNoteDetails();
    }, [noteId, userId, apiUrl]);

    const handleDelete = () => {
        onDelete(noteId);
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
            <div className="bg-white rounded-lg w-full max-w-xl overflow-hidden">
                <div className="flex justify-between items-center px-4 py-2 bg-gray-200">
                    <h2 className="text-lg font-semibold text-black">Note Details</h2>
                    <button onClick={onClose} className="text-black focus:outline-none hover:bg-custom-orange rounded-full p-2">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>
                {loading ? (
                    <div className="p-6">Loading...</div>
                ) : (
                    <div className="p-6">
                        <h2 className="text-2xl font-semibold text-gray-800">Title: {noteDetails.title}</h2>
                        <p className="text-sm text-gray-500">Category: {noteDetails.category}</p>
                        <p className="text-gray-700 mt-2">Description: {noteDetails.description}</p>
                       
                        {nomineeDetails && (
                            <div>
                            
                                <p className="text-gray-700 mt-2">Nominee: {nomineeDetails.name}</p>
                                <p className="text-gray-700 mt-2">Nominee email: {nomineeDetails.email_id}</p>
                            </div>
                        )}
                        <button onClick={handleDelete} className="bg-custom-orange text-white rounded-md px-4 py-2 mt-4 focus:outline-none hover:bg-orange-600 transition-colors duration-100">
                            Delete
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default NoteDetails;
