import React from 'react';
import { Link } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa';
import '@fontsource/montserrat';

const LogoutPage = () => {
  return (
    <div className="h-screen bg-logout-bg bg-cover bg-center flex items-center justify-center text-center text-black font-montserrat">
      <div className="absolute top-40">
        <h1 className="text-6xl mb-8 font-semibold">THANK YOU FOR YOUR VISIT</h1>
        <p className="text-3xl mb-4">YOU ARE NOW LOGGED OUT.</p>
        <Link to="/" className="mt-6 bg-custom-orange text-black text-bold px-3 py-2 rounded-md flex items-center mt-10 justify-center mx-auto w-max">
          <FaArrowLeft className="mr-2" />
          Landing Page
        </Link>
      </div>
    </div>
  );
};

export default LogoutPage;
