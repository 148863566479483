import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import HomeNavbar from './homeNavbar';
import Notes from '../notes/Notes';
import Nominees from '../nominees/Nominees';
import NoteDetails from '../notes/NoteDetails';
import UserContext from '../ContextApi/UserContext';
import Dashboard from '../Dashboard/DashBoard';
import { FaHome } from 'react-icons/fa';
import { HiPencilSquare } from "react-icons/hi2";
import { GrGroup } from "react-icons/gr";

const HomeLayout = () => {
  const navigate = useNavigate();
  const { user, fetchUser } = useContext(UserContext) || { user: null, fetchUser: () => {} };
  const [activeSection, setActiveSection] = useState(localStorage.getItem('activeSection') || 'Your Notes');
  const [selectedNote, setSelectedNote] = useState(null);

  const handleSectionChange = (section) => {
    setActiveSection(section);
    localStorage.setItem('activeSection', section); // Save active section to localStorage
  };

  const handleNoteClick = (note) => {
    setSelectedNote(note);
  };

  const handleCloseNoteDetails = () => {
    setSelectedNote(null);
  };

  const handleDeleteNote = (noteId) => {
    setSelectedNote(null);
  };

  return (
    <div className="flex flex-col h-screen">
      <HomeNavbar />
      <div className="flex flex-grow">
        <div className="w-1/5 text-xl mt-3 p-1 border-r border-gray-300 relative">
          {/* Dashboard Section */}
          <div className="ml-4 mb-6 relative">
            <h2
              className={`font-semibold mb-2 relative group cursor-pointer ${
                activeSection === 'Dashboard' ? 'text-orange-500' : 'text-black'
              }`}
              onClick={() => handleSectionChange('Dashboard')}
            >
              <span className="mr-1 inline-flex lg:py-3 lg:px-2 xl:py-3 xl:px-4 items-center text-black">
                <FaHome />
              </span>
              Dashboard
              {activeSection === 'Dashboard' && (
                <span className="absolute inset-0 mr-8 py-6 border border-orange-500 rounded-md pointer-events-none z-10"></span>
              )}
            </h2>
          </div>
          <div className="ml-4 mb-6 relative">
            <h2
              className={`font-semibold mb-2 relative group cursor-pointer ${
                activeSection === 'Your Notes' ? 'text-orange-500' : 'text-black'
              }`}
              onClick={() => handleSectionChange('Your Notes')}
            >
              <span className="mr-1 inline-flex lg:py-3 lg:px-2 xl:py-3 xl:px-4 items-center text-black">
                <HiPencilSquare />
              </span>
              Your Notes
              {activeSection === 'Your Notes' && (
                <span className="absolute inset-0 mr-8 py-6 border border-orange-500 rounded-md pointer-events-none z-10"></span>
              )}
            </h2>
          </div>
          <div className="ml-4 mb-6 relative">
            <h2
              className={`font-semibold mb-2 relative group cursor-pointer ${
                activeSection === 'Your Nominees' ? 'text-orange-500' : 'text-black'
              }`}
              onClick={() => handleSectionChange('Your Nominees')}
            >
              <span className="mr-1 inline-flex lg:py-3 lg:px-2 xl:py-3 xl:px-4 items-center text-black">
                <GrGroup />
              </span>
              Your Nominees
              {activeSection === 'Your Nominees' && (
                <span className="absolute inset-0 mr-8 py-6 border border-orange-500 rounded-md pointer-events-none z-10"></span>
              )}
            </h2>
          </div>
        </div>
        <div className="w-4/5 bg-white p-4">
          {/* Conditional rendering based on activeSection */}
          {activeSection === 'Your Notes' ? <Notes onNoteClick={handleNoteClick} /> : activeSection === 'Your Nominees' ? <Nominees /> : <Dashboard handleSectionChange={handleSectionChange} />}
        </div>
      </div>
      {selectedNote && <NoteDetails note={selectedNote} onClose={handleCloseNoteDetails} onDelete={handleDeleteNote} />}
    </div>
  );
};

export default HomeLayout;
