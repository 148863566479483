import React, { useState, useContext, useEffect } from "react";
import "./navbar.css";
import { Link } from "react-router-dom";
import UserContext from "../ContextApi/UserContext";
import { FaBars, FaTimes } from 'react-icons/fa';

const Navbar = () => {
  const { user } = useContext(UserContext);
  const [isNavOpen, setIsNavOpen] = useState(false);

  const handleNavClick = () => {
    setIsNavOpen(!isNavOpen);
    document.body.classList.toggle('scroll-disabled', !isNavOpen);
  };
  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth', smooth:'easeInOutQuart' });
  };

  const closeNav = () => {
    setIsNavOpen(false);
    document.body.classList.remove('scroll-disabled');
  };

  useEffect(() => {
    // Clean up by removing the scroll-disabled class if the component unmounts while the navbar is open
    return () => {
      document.body.classList.remove('scroll-disabled');
    };
  }, []);

  return (
    <header>
      <nav className="navbar">
        <div className="navbar-left">
          <Link to="/" className="navbar-brand-link" onClick={handleScrollToTop}>
            <span className="navbar-brand"  >anantar</span>
          </Link>
        </div>
        <div className={`navbar-right ${isNavOpen ? 'active' : ''}`}>
          <span className="link home-link">Home</span>
          <span className="link">Security</span>
          <span className="link">Docs</span>
          <span className="link">Contact</span>
          {user ? (
            <Link to="/home">
              <button className="button-link">Home</button>
            </Link>
          ) : (
            <Link to="/register">
              <button className="button-link">Sign In</button>
            </Link>
          )}
        </div>
      </nav>
      <div className={`nav-icon`} onClick={handleNavClick}>
        {isNavOpen ? <FaTimes /> : <FaBars />}
      </div>
      {isNavOpen && <div className="overlay" onClick={closeNav}></div>}
    </header>
  );
};

export default Navbar;
