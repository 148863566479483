import React, { useState } from "react";
import SignupCss from "./Signup.module.css";
import { BsFillPersonFill, BsFillEyeFill } from "react-icons/bs";
import { BiLogoGmail } from "react-icons/bi";
import { FcGoogle } from "react-icons/fc";
import { Link } from "react-router-dom";
import SignupWithGoogleButton from './SignupWithGoogleButton'; // Adjust the path as per your project structure

const Signup = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [eye, setEye] = useState(true);
  const handleEyeClick = () => {
    setEye(!eye);
  };

  return (
    <>
      <div className={SignupCss.main}>
        <div className={SignupCss.box}>
          <h1 className={SignupCss.create}>Create new account<span className={SignupCss.rdot}>.</span></h1>
          <p className={SignupCss.already}>
            Already a member?{" "}
            <Link to="/login">
              <span className={SignupCss.log}>Login</span>
            </Link>
          </p>
          <div className={SignupCss.name}>
            <div className={SignupCss.inputContainer2}>
              <input
                type="text"
                placeholder="First name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                className={SignupCss.first}
              />
              <BsFillPersonFill className={SignupCss.logo1} />
            </div>
            <div className={SignupCss.inputContainer2}>
              <input
                type="text"
                placeholder="Last name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                className={SignupCss.first}
              />
              <BsFillPersonFill className={SignupCss.logo1} />
            </div>
          </div>
          <div className={SignupCss.inputContainer1}>
            <input
              type="text"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className={SignupCss.email}
            />
            <BiLogoGmail className={SignupCss.logo2} />
          </div>
          <div className={SignupCss.inputContainer1}>
            <input
              type={eye ? "password" : "text"}
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className={SignupCss.email}
            />
            <BsFillEyeFill onClick={handleEyeClick} className={SignupCss.logo2} />
          </div>
          <button type="submit" className={SignupCss.account}>
            Create Account
          </button>
          <div className={SignupCss.line}></div>
          <div className={SignupCss.signupDiv}>
          <FcGoogle className={SignupCss.logo3} />
          <SignupWithGoogleButton
      />
          </div>
        </div>
      </div>
    </>
  );
};

export default Signup;
