import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Landing from "./components/landing/landing";
import Navbar from "./components/navbar/navbar";
import Register from "./components/register/register";
import Home from './components/home/home';
import './App.css';
import { UserProvider } from './components/ContextApi/UserContext';
import ProtectedRoute from './components/Protectedroute/ProtectedRoute';
import PageNotFound from './components/ExtraPages/PageNotFound';
import LogoutPage from './components/ExtraPages/LogoutPage';
import TokenHandler from "../src/components/register/TokenHandler"
import RedirectRoute from './components/Protectedroute/RedirectRoute';

function AppContent() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/auth/google/callback" element={<TokenHandler></TokenHandler>} />
        <Route path="/register" element={<RedirectRoute element={<Register />} redirectTo="/home" />} />
        <Route path="/home" element={<ProtectedRoute element={<Home />} />} />
        <Route path="/pagenotfound" element={<PageNotFound />} /> 
        <Route path="/loggedout" element={<LogoutPage />} /> 
        <Route path="*" element={<Navigate to="/pagenotfound" />} />
      </Routes>
    </div>
  );
}
export default function App() {
  return (
    <Router>
      <UserProvider> 
        <AppContent />
      </UserProvider>
    </Router>
  );
}
