import React, { useState } from 'react';
import { useCollapse } from 'react-collapsed';


function NomineeSelector({ nominees, selectedNominee, setSelectedNominee }) {
    const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();

    return (
        <div className="mb-6">
            <label htmlFor="nominee" className="block text-sm font-medium text-gray-700"></label>
            <div className="flex items-center justify-between cursor-pointer" {...getToggleProps()}>
                <span className='text-m font-medium mb-1'> Choose Nominee</span>
                <span>{isExpanded ? '▲' : '▼'}</span>
            </div>
            <div {...getCollapseProps()}>
                {nominees.length > 0 ? (
                    <div className="border border-gray-400 rounded-md p-3 w-full max-h-32 overflow-y-auto">
                        {nominees.map((nominee) => (
                            <div key={nominee.id} className="flex items-center justify-between">
                                <span>{nominee.name}</span>
                                <input
                                    type="radio"
                                    name="nominee"
                                    value={nominee.id}
                                    checked={selectedNominee === nominee.id}
                                    onChange={() => setSelectedNominee(nominee.id)}
                                />
                            </div>
                        ))}
                    </div>
                ) : (
                    <p className="text-gray-500">No nominees available. Please add nominees to your profile.</p>
                )}
            </div>
        </div>
    );
}

export default NomineeSelector;
