import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const SignupWithGoogleButton = () => {
  const navigate = useNavigate();
  const handleSignIn = () => {
    const googleAuthUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${process.env.REACT_APP_GOOGLE_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_GOOGLE_REDIRECT_URI}&response_type=token&scope=profile%20email`;                     
    window.location.href = googleAuthUrl;
  };
  return (
    <div>
      <button onClick={handleSignIn}>Sign in with Google</button>
    </div>
  );
};

export default SignupWithGoogleButton;
