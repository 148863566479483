import React, { useState, useContext, useEffect } from 'react';
import UserContext from '../ContextApi/UserContext'; // Update the path

const NomineeForm = ({ onClose, onSubmit }) => {
    const { user,getCookie } = useContext(UserContext); // Access the user context
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [formError, setFormError] = useState('');
    const [existingEmails, setExistingEmails] = useState([]);
    const csrftoken = getCookie('csrftoken'); 
    const apiUrl = process.env.REACT_APP_API_URL; // New variable for API URL
    useEffect(() => {
        const fetchNominees = async () => {
            try {
                const userId = user._id;
                const response = await fetch(`${apiUrl}/api/users/${userId}/nominees/`);
                if (!response.ok) {
                    throw new Error('Failed to fetch nominees');
                }
                const data = await response.json();
                const emails = data.map(nominee => nominee.email_id);
                setExistingEmails(emails);
            } catch (error) {
                console.error('Error fetching nominees:', error);
            }
        };

        if (user && user._id) {
            fetchNominees();
        }
    }, [user, apiUrl]);

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        // Check if the form fields are filled
        if (!name || !email) {
            setFormError('All fields are required');
            return;
        }
        if (email === user.email) {
            setFormError('Cannot add the signed in email, try another');
            return;
        }
    
        // Check if the email already exists
        if (existingEmails.includes(email)) {
            setFormError('This email is already used');
            return;
        }
    
        try {
            // Send form data to the backend
            const userId = user._id;
        
            const response = await fetch(`${apiUrl}/api/users/${userId}/nominees/create/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': csrftoken,
                },
                body: JSON.stringify({ name, email_id:email}),
            });
            // console.log(JSON.stringify({ name, email_id:email}))
            if (!response.ok) {
                throw new Error('Failed to submit nominee');
            }
            const data = await response.json();
            console.log('Nominee successfully added:');
            onSubmit(data); 
    
        } catch (error) {
            console.error('Error submitting nominee:', error);
        } finally {
            onClose(); 
        }
    };
    return (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
            <div className="bg-white rounded-md max-w-lg overflow-hidden">
                <form onSubmit={handleSubmit} className="p-6 w-96">
                    <div className="mb-6">
                        <label htmlFor="name" className="block text-sm mb-1 font-medium text-black">Nominee Name</label>
                        <input type="text" id="name" name="name" className="border border-gray-400 rounded-md p-2 w-full" autocomplete="name"  value={name} onChange={(e) => setName(e.target.value)} />
                    </div>
                    <div className="mb-6">
                        <label htmlFor="email" className="block text-sm mb-1 font-medium text-black">Email ID</label>
                        <input type="email" id="email" name="email" className="border border-gray-400 rounded-md p-2 w-full" autocomplete="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                    </div>
                    {formError && <p className="text-red-500 text-sm mb-4">{formError}</p>}
                    <div className="flex justify-end">
                        <button type="button" className="bg-gray-300 text-gray-700 rounded-md px-4 py-2 mr-2 focus:outline-none hover:bg-gray-400 transition-colors duration-300" onClick={onClose}>Cancel</button>
                        <button type="submit" className="bg-custom-orange text-white rounded-md px-4 py-2 focus:outline-none hover:bg-custom-orange-hover transition-colors duration-300">Submit</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default NomineeForm;
