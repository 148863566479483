import React, { useContext, useEffect, useState } from 'react';
import UserContext from '../ContextApi/UserContext';
import namaste from '../assets/imagine.life 1.png';
import '@fontsource/montserrat';

const Dashboard = ({ handleSectionChange }) => {
  const { user } = useContext(UserContext);
  const apiUrl = process.env.REACT_APP_API_URL;
  const [notes, setNotes] = useState([]);
  const [hasNominees, setHasNominees] = useState([]);
  const [hasNotes, setHasNotes] = useState(false); // State to track if user has notes
  let userId = "";
  if (user) {
      // console.log(user);
      userId = user._id;
  }
  useEffect(() => {
      const fetchNotes = async () => {
          try {
              const response = await fetch(`${apiUrl}/api/users/${userId}/notes`);
              if (!response.ok) {
                  throw new Error('Failed to fetch notes');
              }
              const data = await response.json();
              // setNotes(data);
              // Check if user has notes
              setHasNotes(data.length > 0);
          } catch (error) {
              console.error('Error fetching notes:', error);
          }
      };
      if (userId) {
          fetchNotes();
      }
      
  }, [userId, apiUrl]);
  const fetchNominees = async () => {
    try {
        const response = await fetch(`${apiUrl}/api/users/${userId}/nominees`);
        if (!response.ok) {
            throw new Error('Failed to fetch nominees');
        }
        const data = await response.json();
        setHasNominees(data.length > 0);
        // setNominees(data);
    } catch (error) {
        console.error('Error fetching nominees:', error);
    }
};
useEffect(() => {
    if (userId) {
        fetchNominees();
    }
}, [userId, apiUrl]);
  
  const firstName = user && user.name ? user.name.split(' ')[0] : 'Guest';

  const handleCreateNote = () => {
      handleSectionChange('Your Notes');
    //   handleSectionChange('Your Notes'); // Change section to 'Your Notes' if user has notes
    // } else {
    //   handleSectionChange('Your Notes'); // Otherwise, stay on 'Create Note'
    
  };
  const handleCreateNominee = () => {

      handleSectionChange('Your Nominees');
    //   handleSectionChange('Your Notes'); // Change section to 'Your Notes' if user has notes
    // } else {
    //   handleSectionChange('Your Notes'); // Otherwise, stay on 'Create Note'

  };
  return (
    <div>
      <div className="mx-auto my-10 rounded-3xl w-11/12 relative shadow-md" style={{ marginTop: '4rem', backgroundColor: '#FFDDA4' }}>
        <div className="flex flex-col md:flex-row font-montserrat">
          <div className="md:w-1/3 mt-1 ml-4 relative" style={{ marginTop: '-4rem' }}>
            <img src={namaste} alt="namaste" className="" />
          </div>
          <div className="md:w-2/3 p-1 mt-0 md:pl-6 pt-4">
            <h1 className="text-4xl font-bold mt-8 md:mb-6 md:mt-5 text-orange-500">Welcome, {firstName}</h1>
            <div className="max-w-2xl">
              <p className="text-lg">
                We are here to secure your data and all the necessary information you want to be currently secure and wanna share it with someone you are really close with.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="mx-auto mt-20 my-10 w-10/12 font-montserrat">
        <h2 className="text-3xl font-semibold mb-8 tracking-wide" style={{ color: '#FF7B00' }}>What's a note?</h2>
        <p className="text-lg mb-8 mt-4 leading-relaxed">
          We are here to secure your data and all the necessary information you want to be currently secure and wanna share it with someone you are really close with.
        </p>
        <button
          className="bg-orange-500 text-white px-10 py-3 mt-3 rounded-md hover:bg-orange-600"
          onClick={handleCreateNote}
        >
          {hasNotes ? 'View Notes' : 'Create Your First Note'}
        </button>
      </div>

      <div className="mx-auto mt-20 my-10 w-10/12 font-montserrat">
        <h2 className="text-3xl font-semibold mb-8 tracking-wide" style={{ color: '#FF7B00' }}>What's a Nominee?</h2>
        <p className="text-lg mb-8 mt-4 leading-relaxed">
          We are here to secure your data and all the necessary information you want to be currently secure and wanna share it with someone you are really close with.
        </p>
        <button
          className="bg-orange-500 text-white px-10 py-3 mt-3 rounded-md hover:bg-orange-600"
          onClick={handleCreateNominee}
        >
          {hasNominees ? 'View Nominees' : 'Create Your First Nominee'}
        </button>
      </div>
    </div>
  );
};

export default Dashboard;
