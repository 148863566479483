// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../assets/Fonts/Jockey_One/JockeyOne-Regular.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
    font-family: 'jocky';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  }
  

  `, "",{"version":3,"sources":["webpack://./src/components/home/homeNavbar.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,4CAA4D;EAC9D","sourcesContent":["@font-face {\n    font-family: 'jocky';\n    src: url('../assets/Fonts/Jockey_One/JockeyOne-Regular.ttf');\n  }\n  \n\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
