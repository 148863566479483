import React, { createContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const UserContext = createContext();
export const UserProvider = ({ children }) => {
  const navigate = useNavigate();
  const [user, setUser] = useState([]);
  const apiUrl = process.env.REACT_APP_API_URL; 
  const fetchUser = () => {
    fetch(`${apiUrl}/api/user-info/`, {
      method: 'GET',
      credentials: 'include',
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        setUser(data);
      })
      .catch(error => {
        console.error('Error fetching user:', error);
        setUser(null);
      });
  };
  const logout = async () => {
    try {
      const response = await fetch(`${apiUrl}/api/logout/`, {
        method: 'GET',
        credentials: 'include'
      });
      if (!response.ok) {
        throw new Error('Logout failed');
      }
    } catch (error) {
      throw new Error('Logout failed');
    }
    console.log('User logged out successfully');
  };
  function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}

  useEffect(() => {
    fetchUser();
  }, []);

  return (
    <UserContext.Provider value={{ user, fetchUser, logout, getCookie }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserContext;
