import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa';
import '@fontsource/montserrat';
import UserContext from '../ContextApi/UserContext';

const PageNotFound = () => {
  const { user } = useContext(UserContext);

  return (
    <div className="h-screen bg-page-not-found bg-cover bg-center flex items-center justify-center text-center text-black font-montserrat">
      <Link to={user ? "/home" : "/"} className="absolute top-8 left-8 bg-custom-orange text-black text-bold px-4 py-2 rounded-md flex items-center">
        <FaArrowLeft className="mr-2" />
        Go Back
      </Link>
      <div className="absolute top-40 bg-white w-3/5 p-8 rounded-lg">
        <h1 className="text-4xl mb-6 font-semibold">ERROR 404</h1>
        <p className="text-xl mb-4">Page not found</p>
        <p className="text-lg w-3/4 mx-auto">The page you requested doesn’t exist. If you think it’s an error, contact support.</p>
      </div>
    </div>
  );
}

export default PageNotFound;
