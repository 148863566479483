// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
    margin: 0;
    padding: 0;
    /* font-family: Arial, sans-serif; */
    /* background: linear-gradient(to left, #BAEDFE, white); */
    /* height: 100vh; */
    /* display: flex;
    justify-content: center; */
    align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;IACI,SAAS;IACT,UAAU;IACV,oCAAoC;IACpC,0DAA0D;IAC1D,mBAAmB;IACnB;8BAC0B;IAC1B,mBAAmB;AACvB","sourcesContent":["body {\r\n    margin: 0;\r\n    padding: 0;\r\n    /* font-family: Arial, sans-serif; */\r\n    /* background: linear-gradient(to left, #BAEDFE, white); */\r\n    /* height: 100vh; */\r\n    /* display: flex;\r\n    justify-content: center; */\r\n    align-items: center;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
