import React, { useState, useEffect, useContext } from 'react';
import UserContext from '../ContextApi/UserContext';
import NomineeSelector from './NomineeSelector';

const NoteForm = ({ onClose, onSubmit }) => {
    const [title, setTitle] = useState('');
    const [category, setCategory] = useState('');
    const [description, setDescription] = useState('');
    const [selectedNominee, setSelectedNominee] = useState('');
    const [nominees, setNominees] = useState([]);
    const [formError, setFormError] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const { user, getCookie } = useContext(UserContext);
    const csrftoken = getCookie('csrftoken');

    const apiUrl = process.env.REACT_APP_API_URL;

    let userId = "";
    if (user) {
        userId = user._id;
    }

    useEffect(() => {
        const fetchNominees = async () => {
            try {
                const response = await fetch(`${apiUrl}/api/users/${userId}/nominees/`);
                if (!response.ok) {
                    throw new Error('Failed to fetch nominees');
                }
                const data = await response.json();
                setNominees(data);
            } catch (error) {
                console.error('Error fetching nominees:', error);
            }
        };

        if (userId) {
            fetchNominees();
        }
    }, [userId, apiUrl]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!title || !category || !description || !selectedNominee) {
            setFormError(true);
            return;
        }
        const csrftoken = getCookie('csrftoken');

        const newNote = {
            title,
            category,
            description,
            nomineeId: selectedNominee,
        };

        try {
            const response = await fetch(`${apiUrl}/api/users/${userId}/notes/create/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': csrftoken,
                },
                body: JSON.stringify(newNote),
            });

            if (!response.ok) {
                throw new Error('Failed to save the note');
            }

            const savedNote = await response.json();
            setSuccessMessage('New Note Created');
            onSubmit(savedNote);
            setTimeout(() => {
                setSuccessMessage('');
                onClose();
            }, 2000); // Close the form after 2 seconds
        } catch (error) {
            console.error('Error saving the note:', error);
        }
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white rounded-md max-w-lg overflow-hidden relative">
                <button onClick={onClose} className="absolute top-3 right-2 text-black-500 p-2 focus:outline-none hover:bg-custom-orange rounded-full">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </button>
                <h2 className="text-2xl font-semibold text-black bg-color-navbar py-4 px-6">Add Note</h2>
                <form onSubmit={handleSubmit} className="p-6 w-96">
                    <div className="mb-6">
                        <label htmlFor="title" className="block text-sm mb-1 font-medium text-black-700">Note Title</label>
                        <input type="text" id="title" name="title" className="border border-gray-400 rounded-md p-2 w-full" value={title} onChange={(e) => setTitle(e.target.value)} />
                    </div>
                    <div className="mb-6">
                        <label htmlFor="category" className="block text-sm mb-1 font-medium text-black-700">Note Category</label>
                        <input type="text" id="category" name="category" className="border border-gray-400 rounded-md p-2 w-full" value={category} onChange={(e) => setCategory(e.target.value)} />
                    </div>
                    <div className="mb-6">
                        <label htmlFor="description" className="block text-sm mb-1 font-medium text-black-700">Note Description</label>
                        <textarea id="description" name="description" className="border border-gray-400 rounded-md p-2 w-full h-28 resize-none" value={description} onChange={(e) => setDescription(e.target.value)}></textarea>
                    </div>
                    <NomineeSelector
                        nominees={nominees}
                        selectedNominee={selectedNominee}
                        setSelectedNominee={setSelectedNominee}
                    />
                    {formError && <p className="text-red-500 text-sm mb-4">All fields are required</p>}
                    {successMessage && <p className="text-green-500 text-sm mb-4">{successMessage}</p>}
                    <div className="flex justify-end">
                        <button type="button" className="bg-black-300 text-black-700 rounded-md px-4 py-2 mr-2 focus:outline-none hover:bg-black-400 transition-colors duration-300" onClick={onClose}>Cancel</button>
                        <button type="submit" disabled={!title || !category || !description || !selectedNominee} className={`bg-custom-orange text-white rounded-md px-4 py-2 focus:outline-none hover:bg-color-navbar transition-colors duration-100 ${(!title || !category || !description || !selectedNominee) && 'cursor-not-allowed'}`}>Submit</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default NoteForm;
